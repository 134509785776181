import "./azi-objectives.scss?inline";
import React from "react";
import { Translate } from "react-i18nify";

export class AziObjectives extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentDescriptionStoreDesc: [],
            currentDescriptionStoreOpenedTab: null,
        };
    }
    supplierDescription = [
        "home.objectivesSection.supplier.benefit1",
        "home.objectivesSection.supplier.benefit2",
        "home.objectivesSection.supplier.benefit3",
        "home.objectivesSection.supplier.benefit4",
    ];
    recipientDescription = [
        "home.objectivesSection.recipient.benefit1",
        "home.objectivesSection.recipient.benefit2",
        "home.objectivesSection.recipient.benefit3",
        "home.objectivesSection.recipient.benefit4",
    ];
    ecommerceDescription = [
        "home.objectivesSection.ecommerce.benefit1",
        "home.objectivesSection.ecommerce.benefit2",
        "home.objectivesSection.ecommerce.benefit3",
        "home.objectivesSection.ecommerce.benefit4",
    ];

    render() {
        return (
            <>
                <div className="azi-objectives-section section-padding">
                    <h2 data-aos="fade-right" className="section-header">
                        <Translate value="home.objectivesSection.header" />
                    </h2>
                    <div
                        data-aos="fade-right"
                        className="azi-objectives-container"
                        id="objectives-container"
                    >
                        <div
                            className={
                                this.state.currentDescriptionStoreOpenedTab ===
                                1
                                    ? "azi-objectives-element azi-objectives-element-selected"
                                    : "azi-objectives-element"
                            }
                        >
                            <div className="azi-objectives-element-image">
                                <picture>
                                    <source
                                        srcSet="/icons/objectives/recipient.webp"
                                        type="image/webp"
                                    />
                                    <img
                                        src="/icons/objectives/recipient.png"
                                        alt="recipient icon"
                                    />
                                </picture>
                            </div>
                            <div>
                                <div className="azi-objectives-element-header">
                                    <Translate value="home.objectivesSection.supplier.header" />
                                </div>
                                <div className="azi-objectives-element-description">
                                    <Translate value="home.objectivesSection.supplier.subHeader" />
                                </div>
                            </div>
                            <div
                                className="azi-objectives-element-button azi-button azi-button-primary"
                                onClick={() => {
                                    this.setState({
                                        currentDescriptionStoreDesc:
                                            this.supplierDescription,
                                    });
                                    this.setState({
                                        currentDescriptionStoreOpenedTab: 1,
                                    });
                                }}
                            >
                                <Translate value="home.objectivesSection.knowMore" />
                            </div>
                            <div
                                hidden={
                                    this.state
                                        .currentDescriptionStoreOpenedTab !== 1
                                }
                                className="mobile azi-objectives-description-container animate__animated animate__fadeInDown"
                            >
                                {this.state.currentDescriptionStoreDesc.map(
                                    (item) => (
                                        <div
                                            key={item}
                                            className="azi-objectives-description-element"
                                        >
                                            <img
                                                src="/icons/shared/green-check.svg"
                                                alt="green check icon"
                                            />
                                            <Translate value={item} />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>

                        <div
                            className={
                                this.state.currentDescriptionStoreOpenedTab ===
                                2
                                    ? "azi-objectives-element azi-objectives-element-selected"
                                    : "azi-objectives-element"
                            }
                        >
                            <div className="azi-objectives-element-image">
                                <picture>
                                    <source
                                        srcSet="/icons/objectives/suppiler.webp"
                                        type="image/webp"
                                    />
                                    <img
                                        src="/icons/objectives/suppiler.png"
                                        alt="suppiler icon"
                                    />
                                </picture>
                            </div>
                            <div>
                                <div className="azi-objectives-element-header">
                                    <Translate value="home.objectivesSection.recipient.header" />
                                </div>
                                <div className="azi-objectives-element-description">
                                    <Translate value="home.objectivesSection.recipient.subHeader" />
                                </div>
                            </div>

                            <div
                                className="azi-objectives-element-button azi-button azi-button-primary"
                                onClick={() => {
                                    this.setState({
                                        currentDescriptionStoreDesc:
                                            this.recipientDescription,
                                    });
                                    this.setState({
                                        currentDescriptionStoreOpenedTab: 2,
                                    });
                                }}
                            >
                                <Translate value="home.objectivesSection.knowMore" />
                            </div>

                            <div
                                hidden={
                                    this.state
                                        .currentDescriptionStoreOpenedTab !== 2
                                }
                                className="mobile azi-objectives-description-container animate__animated animate__fadeInDown"
                            >
                                {this.state.currentDescriptionStoreDesc.map(
                                    (item) => (
                                        <div
                                            key={item}
                                            className="azi-objectives-description-element"
                                        >
                                            <img
                                                src="/icons/shared/green-check.svg"
                                                alt="green check icon"
                                            />
                                            <Translate value={item} />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>

                        <div
                            className={
                                this.state.currentDescriptionStoreOpenedTab ===
                                3
                                    ? "azi-objectives-element azi-objectives-element-selected"
                                    : "azi-objectives-element"
                            }
                        >
                            <div className="azi-objectives-element-image">
                                <picture>
                                    <source
                                        srcSet="/icons/objectives/e-commerce.webp"
                                        type="image/webp"
                                    />
                                    <img
                                        src="/icons/objectives/e-commerce.png"
                                        alt="e-commerce icon"
                                    />
                                </picture>
                            </div>
                            <div>
                                <div className="azi-objectives-element-header">
                                    <Translate value="home.objectivesSection.ecommerce.header" />
                                </div>
                                <div className="azi-objectives-element-description">
                                    <Translate value="home.objectivesSection.ecommerce.subHeader" />
                                </div>
                            </div>

                            <div
                                className="azi-objectives-element-button azi-button azi-button-primary"
                                onClick={() => {
                                    this.setState({
                                        currentDescriptionStoreDesc:
                                            this.ecommerceDescription,
                                    });
                                    this.setState({
                                        currentDescriptionStoreOpenedTab: 3,
                                    });
                                }}
                            >
                                <Translate value="home.objectivesSection.knowMore" />
                            </div>
                            <div
                                hidden={
                                    this.state
                                        .currentDescriptionStoreOpenedTab !== 3
                                }
                                className="mobile azi-objectives-description-container animate__animated animate__fadeInDown"
                            >
                                {this.state.currentDescriptionStoreDesc.map(
                                    (item) => (
                                        <div
                                            key={item}
                                            className="azi-objectives-description-element"
                                        >
                                            <img
                                                src="/icons/shared/green-check.svg"
                                                alt="green check icon"
                                            />
                                            <Translate value={item} />
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${
                            this.state.currentDescriptionStoreOpenedTab
                                ? "unmobile azi-objectives-description-container animate__animated animate__slideInDown"
                                : "unmobile-hide"
                        }`}
                    >
                        {this.state.currentDescriptionStoreDesc.map((item) => (
                            <div
                                key={item}
                                className="azi-objectives-description-element"
                            >
                                <img
                                    src="/icons/shared/green-check.svg"
                                    alt="green check icon"
                                />
                                <Translate value={item} />
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    }
}

export default AziObjectives;
