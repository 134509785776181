import React from "react";
import "./scheme.scss";
import { Translate } from "react-i18nify";
import { getLocale } from "react-i18nify";

function Scheme() {
    var src = '/images/azi-main-page-section/azimuthe-structure-min-' + getLocale() + '.webp'
    var srcPng = '/images/azi-main-page-section/azimuthe-structure-min-' + getLocale() + '.png'
    return (
        <>
            <div className="background">
                <div data-aos="fade-right" className="scheme-description">
                    <Translate value="home.scheme.descriptionPart1" />
                    <span className="highlight-text-light">
                        {" "}
                        <Translate value="home.scheme.descriptionHighlight" />{" "}
                    </span>
                    <Translate value="home.scheme.descriptionPart2" />
                </div>
                <div data-aos="fade-down" className="azi-scheme-container">
                    <picture>
                        <source srcSet={src} type="image/webp"/>
                        <img
                            src={srcPng}
                            alt="azimuthe schematic"
                        />
                    </picture>
                </div>
            </div>
        </>
    );
}

export default Scheme;
