import "./azi-packages.scss?inline";
import React from "react";
import { Translate } from "react-i18nify";
import Dialog from "@mui/material/Dialog";

export class AziPackages extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentModal: null,
            dialogOpen: false,
            modalContent: {
                title: "",
                description: "",
                descriptionPart1: "",
                descriptionPart2: "",
                descriptionHighlight: "",
                photo: "",
                descPoints: [""],
            },
        };
    }

    modalsContent = [
        {
            title: "offer.packagesSection.qc.modal.title",
            descriptionPart1: "offer.packagesSection.qc.modal.descriptionPart1",
            descriptionPart2: "offer.packagesSection.qc.modal.descriptionPart2",
            descriptionHighlight:
                "offer.packagesSection.qc.modal.descriptionHighlight",
            description: "offer.packagesSection.qc.modal.description",
            photo: "/images/azi-offer-page-section/qc.gif",
            descPoints: [
                "offer.packagesSection.qc.modal.benefit1",
                "offer.packagesSection.qc.modal.benefit2",
                "offer.packagesSection.qc.modal.benefit3",
                "offer.packagesSection.qc.modal.benefit4",
            ],
        },
        {
            title: "offer.packagesSection.acm.modal.title",
            descriptionPart1:
                "offer.packagesSection.acm.modal.descriptionPart1",
            descriptionPart2:
                "offer.packagesSection.acm.modal.descriptionPart2",
            descriptionHighlight:
                "offer.packagesSection.acm.modal.descriptionHighlight",
            description: "offer.packagesSection.acm.modal.description",
            photo: "/images/azi-offer-page-section/acm.gif",
            descPoints: [
                "offer.packagesSection.acm.modal.benefit1",
                "offer.packagesSection.acm.modal.benefit2",
                "offer.packagesSection.acm.modal.benefit3",
                "offer.packagesSection.acm.modal.benefit4",
            ],
        },
        {
            title: "offer.packagesSection.photo.modal.title",
            descriptionPart1:
                "offer.packagesSection.photo.modal.descriptionPart1",
            descriptionPart2:
                "offer.packagesSection.photo.modal.descriptionPart2",
            descriptionHighlight:
                "offer.packagesSection.photo.modal.descriptionHighlight",
            description: "offer.packagesSection.photo.modal.description",
            photo: "/images/azi-offer-page-section/photo.gif",
            descPoints: [
                "offer.packagesSection.photo.modal.benefit1",
                "offer.packagesSection.photo.modal.benefit2",
                "offer.packagesSection.photo.modal.benefit3",
            ],
        },
        {
            title: "offer.packagesSection.api.modal.title",
            descriptionPart1:
                "offer.packagesSection.api.modal.descriptionPart1",
            descriptionPart2:
                "offer.packagesSection.api.modal.descriptionPart2",
            descriptionHighlight:
                "offer.packagesSection.api.modal.descriptionHighlight",
            description: "offer.packagesSection.api.modal.description",
            photo: "/images/azi-offer-page-section/api-import.gif",
            descPoints: [
                "offer.packagesSection.api.modal.benefit1",
                "offer.packagesSection.api.modal.benefit2",
                "offer.packagesSection.api.modal.benefit3",
            ],
        },
        {
            title: "offer.packagesSection.export.modal.title",
            descriptionPart1:
                "offer.packagesSection.export.modal.descriptionPart1",
            descriptionPart2:
                "offer.packagesSection.export.modal.descriptionPart2",
            descriptionHighlight:
                "offer.packagesSection.export.modal.descriptionHighlight",
            description: "offer.packagesSection.export.modal.description",
            photo: "/images/azi-offer-page-section/export.gif",
            descPoints: [
                "offer.packagesSection.export.modal.benefit1",
                "offer.packagesSection.export.modal.benefit2",
                "offer.packagesSection.export.modal.benefit3",
            ],
        },
    ];
    packageTilesContent = [
        {
            title: "offer.packagesSection.qc.title",
            desc: "offer.packagesSection.qc.description",
            photo: "/icons/packages/packages-qc.svg",
        },
        {
            title: "offer.packagesSection.acm.title",
            desc: "offer.packagesSection.acm.description",
            photo: "/icons/packages/package-acm.svg",
        },
        {
            title: "offer.packagesSection.photo.title",
            desc: "offer.packagesSection.photo.description",
            photo: "/icons/packages/package-photo.svg",
        },
        {
            title: "offer.packagesSection.api.title",
            desc: "offer.packagesSection.api.description",
            photo: "/icons/packages/package-import.svg",
        },
        {
            title: "offer.packagesSection.export.title",
            desc: "offer.packagesSection.export.description",
            photo: "/icons/packages/package-export.svg",
        },
    ];

    setDialogOpen(isOpen) {
        this.setState({
            dialogOpen: isOpen,
        });
    }

    setDialogClose(isOpen) {
        this.setState({
            dialogOpen: isOpen,
        });
    }

    render() {
        return (
            <>
                <div className="azi-packages-section section-padding">
                    <h2
                        data-aos="fade-right"
                        className="azi-packages-header section-header"
                    >
                        <Translate value="offer.packagesSection.header" />
                    </h2>
                    <div className="azi-packages-container">
                        <div
                            data-aos="fade-right"
                            className="azi-packages-description"
                        >
                            <Translate value="offer.packagesSection.subHeader" />
                        </div>
                        <div className="flex-row flex-justify-content-center">
                            <div className="azi-packages-elements-container">
                                {this.packageTilesContent.map((item, i) => (
                                    <div
                                        data-aos="flip-right"
                                        key={item.title}
                                        className="packages-element"
                                    >
                                        <div className="element-title">
                                            <Translate value={item.title} />
                                        </div>
                                        <div className="element-desc">
                                            <Translate value={item.desc} />
                                        </div>
                                        <div className="element-open-modal-icon">
                                            <img
                                                onClick={() => {
                                                    this.setState({
                                                        currentModal: i,
                                                        modalContent:
                                                            this.modalsContent[
                                                                i
                                                            ],
                                                    });
                                                    this.setDialogOpen(true);
                                                }}
                                                src="/icons/packages/open-modal-icon.svg"
                                                alt="open modal icoon arrow"
                                            />
                                        </div>
                                        <div className="element-photo">
                                            <img
                                                src={item.photo}
                                                alt="modal package icon"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <Dialog
                    PaperProps={{
                        style: { borderRadius: 25 },
                    }}
                    fullWidth
                    maxWidth="md"
                    open={this.state.dialogOpen}
                >
                    <div className="modal">
                        <div className="title">
                            <Translate value={this.state.modalContent.title} />
                        </div>
                        <div className="modal-container">
                            <div className="description">
                                <Translate
                                    value={
                                        this.state.modalContent.descriptionPart1
                                    }
                                />
                                <span className="highlight-text-light">
                                    {" "}
                                    <Translate
                                        value={
                                            this.state.modalContent
                                                .descriptionHighlight
                                        }
                                    />{" "}
                                </span>

                                <Translate
                                    value={
                                        this.state.modalContent.descriptionPart2
                                    }
                                />
                            </div>
                            <div className="benefits">
                                {this.state.modalContent?.descPoints.map(
                                    (item, i) => (
                                        <div
                                            key={i}
                                            className="modal-benefits-element"
                                        >
                                            <img
                                                src="/icons/shared/green-check.svg"
                                                alt="green check icon"
                                            />
                                            <Translate value={item} />
                                        </div>
                                    )
                                )}
                            </div>
                            <div
                                className="azi-button azi-button-primary"
                                onClick={() => {
                                    this.setState({ currentModal: null });
                                    this.setDialogClose(false);
                                }}
                            >
                                <Translate value="offer.packagesSection.close" />
                            </div>
                        </div>

                        <div className="modal-photo">
                            <img
                                src={this.state.modalContent?.photo}
                                alt="modal package animation"
                            />
                        </div>
                    </div>
                </Dialog>
            </>
        );
    }
}

export default AziPackages;
