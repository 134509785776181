import "./header-subpage.scss?inline";
import React from "react";
import { Translate } from "react-i18nify";

export class HeaderSubPage extends React.PureComponent {

    render() {
        return (
            <>
                <header className="header-subpage">
                    <p className="header-subpage-text">
                        <Translate value={this.props.text} />
                    </p>
                </header>
            </>
        );
    }
}

export default HeaderSubPage;
