import "./azi-partners.scss";
import React from "react";
import { Translate } from "react-i18nify";

class AziPartners extends React.PureComponent {
    render() {
        return (
            <>
                <div className="azi-partners-section section-padding">
                    <h2
                        data-aos="fade-left"
                        className="section-header text-gradient-reverse"
                    >
                        <Translate value="home.partnersSection.header" />
                    </h2>
                    <div data-aos="fade-right" className="azi-partners-container">
                        <div className="azi-partner-element-container">
                            <div className="azi-partner-element">
                                <div className="azi-partner-element-photo">
                                    <img
                                        className="brave-logo"
                                        src="/images/azi-main-page-section/partners-brave.svg"
                                        alt="brave logo"
                                        loading="lazy"
                                    />
                                </div>
                                <div className="azi-partner-element-description">
                                    <Translate value="home.partnersSection.beBraveDescription" />
                                </div>
                            </div>
                            <div className="azi-partner-element">
                                <div className="azi-partner-element-photo">
                                    <img
                                        src="/images/azi-main-page-section/GS1-partner-2.png"
                                        alt="gs1 logo"
                                        loading="lazy"
                                        className="gs1-logo"
                                    />
                                </div>
                                <div className="azi-partner-element-description">
                                    <Translate value="home.partnersSection.gs1Description" />
                                </div>
                            </div>
                        </div>
                        <div className="azi-partners-logo-container">
                            <div>
                                <img
                                    src="/images/azi-main-page-section/partners-iph-toruń.svg"
                                    alt="iph toruń logo"
                                    loading="lazy"
                                />
                                <img
                                    src="/images/azi-main-page-section/partners-wsg.svg"
                                    alt="wsg logo"
                                    loading="lazy"
                                />
                            </div>
                            <div>
                                <img
                                    src="/images/azi-main-page-section/partners-iph-bydgoszcz.svg"
                                    alt="iph bydgoszcz logo"
                                    loading="lazy"
                                />
                                <img
                                    src="/images/azi-main-page-section/partners-klaster.svg"
                                    alt="klaster logo"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AziPartners;
