import { setTranslations, setLocale, addLocales, getLocale } from "react-i18nify";
import en from "./en.json";
import pl from "./pl.json";
import es from "./es.json";
import de from "./de.json";
import ua from "./ua.json";

function translations() {
    const selectedLang = localStorage.getItem("lang");
    const userBrowserLang = navigator.language.slice(0, 2);

    let currentLang = selectedLang ? selectedLang : userBrowserLang;


    if(!currentLang){
        this.currentLang = 'en'
    }
    

    setTranslations({
        pl,
        en,
        es,
        de,
        ua,
    });

    addLocales({ pl, en, es, de, ua });

    //rerenderComponents = true
    setLocale(currentLang);
    localStorage.setItem("lang", currentLang);

    const loc = window.location.pathname.slice(1, 3);
    if (Object.keys(awaiableLocation).findIndex((f) => f === loc) !== -1) {
        setLocale(loc);
    }
    else{
        setLocale('en')
    }
}

export const awaiableLocation = {
    pl,
    en,
    es,
    de,
    ua,
};

export function getUrlPath (){
    const locale = getLocale()
    if(locale === 'en'){
        return '/'
    }
     return `/${locale}/`
}

export function setLang (lang) {
    const prevLang = getLocale()
    setLocale(lang);
    localStorage.setItem("lang", lang);
    if (lang === "en") {
        window.history.replaceState(
            null,
            "",
            `${getUrlPath()}${window.location.pathname.slice(4)}`
        );
    }
    else if (prevLang  === 'en'){
        window.history.replaceState(
            null,
            "",
            `/${lang}${window.location.pathname}`
        );
    }
     else {
        window.history.replaceState(
            null,
            "",
            `/${lang}${window.location.pathname.slice(3)}`
        );
    }
    window.location.reload();
};


export default translations;
