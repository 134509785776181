import React from "react";
import "./azi-contact-form.scss?inline";
import { Translate } from "react-i18nify";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import FormComponent from "../../form-component/form-component";

export class AziContactForm extends React.PureComponent {

    render() {
        return (
            <>
                <div className="azi-contact-form-section section-padding">
                    <h2
                        data-aos="fade-up"
                        className="azi-contact-form-header section-header"
                    >
                        <Translate value="offer.contactFormSection.header" />
                    </h2>
                    <div
                        data-aos="fade-right"
                        className="azi-contact-form-container"
                    >
                        <div className="desc">
                            <div>
                                <Translate value="offer.contactFormSection.description" />
                            </div>

                            <img src="/images/nav/azi-logo-blue.svg" alt="" />
                        </div>
                        <div data-aos="fade-right" className="azi-contact-form">
                            <GoogleReCaptchaProvider
                                reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}
                            >
                                <FormComponent />
                            </GoogleReCaptchaProvider>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AziContactForm;
