import "./header.scss?inline?inline";
import React from "react";
import { Translate } from "react-i18nify";

class AziHeader extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            productCount: 0,
            manufacturerCount: 0,
            attribCount: 0,
        };
    }

    componentDidMount() {
        const prodInterval = setInterval(() => {
            this.productCounter();
            if (this.state.productCount > 53000) {
                clearInterval(prodInterval);
                this.setState({
                    productCount: 53000,
                });
            }
        }, 46);
        const manuInterval = setInterval(() => {
            this.manufacturerCounter();
            if (this.state.manufacturerCount > 2200) {
                clearInterval(manuInterval);
                this.setState({
                    manufacturerCount: 2300,
                });
            }
        }, 50);
        const attribInterval = setInterval(() => {
            this.attribCounter();
            if (this.state.attribCount > 3000) {
                clearInterval(attribInterval);
                this.setState({
                    attribCount: 3000,
                });
            }
        }, 60);
    }

    productCounter() {
        this.setState({
            productCount: this.state.productCount + 1000,
        });
    }
    manufacturerCounter() {
        this.setState({
            manufacturerCount: this.state.manufacturerCount + 50,
        });
    }
    attribCounter() {
        this.setState({
            attribCount: this.state.attribCount + 50,
        
        })
    }

    

    render() {
        return (
            <>
                <header>
                    <div className="flex-row vector-img">
                        <div
                            data-aos="fade-right"
                            className="header-text-container"
                        >
                            <p>Azimuthe</p>
                            <h1>
                                <Translate value="home.header.headerTextPart1" />{" "}
                                <span className="highlight-text-light">
                                    <Translate value="home.header.headerHighlightText"></Translate>
                                </span>
                                <Translate value="home.header.headerTextPart2" />
                            </h1>
                            <div className="spacer-20px"></div>
                            <div>
                                <div
                                    className="azi-button azi-button-white know-more-button"
                                    onClick={() => {
                                        let el =
                                            document.getElementById(
                                                "counter-container"
                                            );
                                        window.scroll({
                                            top: el?.offsetTop,
                                            behavior: "smooth",
                                        });
                                    }}
                                >
                                    <Translate value="home.header.knowMore" />
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-left" data-aos-duration="2000" className="cube-image">
                            <img
                                fetchpriority="high"
                                src="/images/azi-main-page-section/azimuthe-isometric-animated.svg"
                                alt="azimuthe product data isometric schemat"
                                loading="eager"
                                decoding="sync"
                            />
                        </div>
                    </div>
                    <div className="flex-row" id="counter-container">
                        <div className="vector-svg">
                            <svg
                                width="100%"
                                height="40vh"
                                preserveAspectRatio="none"
                                viewBox="0 0 836 292"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M653.364 29.1212L0 292V0.5H505H801.178C750.53 0.5 700.352 10.216 653.364 29.1212Z"
                                    fill="#083A5E"
                                />
                            </svg>
                        </div>
                        <div className="header-counter-container">
                            <div className="header-counter-element">
                                <div className="product-count">{this.state.productCount}+</div>
                                <div>
                                    <Translate value="home.header.products" />
                                </div>
                            </div>
                            <div className="header-counter-element">
                                <div className="manufacturer-count">{this.state.manufacturerCount}+</div>
                                <div>
                                    <Translate value="home.header.manufacturers" />
                                </div>
                            </div>
                            <div className="header-counter-element">
                                <div className="attrib-count">{this.state.attribCount}+</div>
                                <div>
                                    <Translate value="home.header.uniqueAttributes" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-counter-container-mobile">
                        <div className="header-counter-element-mobile">
                            <div>{this.state.productCount}+</div>
                            <div>
                                <Translate value="home.header.products" />
                            </div>
                        </div>
                        <div className="header-counter-element-mobile">
                            <div>{this.state.manufacturerCount}+</div>
                            <div>
                                <Translate value="home.header.manufacturers" />
                            </div>
                        </div>
                        <div className="header-counter-element-mobile">
                            <div>{this.state.attribCount}+</div>
                            <div>
                                <Translate value="home.header.uniqueAttributes" />
                            </div>
                        </div>
                    </div>
                </header>
            </>
        );
    }
}

export default AziHeader;
