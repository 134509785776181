import "./nav.scss";
import React from "react";
import classNames from "classnames";
import PositionedMenu from "./positioneMenu";
import { Link, NavLink } from "react-router-dom";
import { Translate } from "react-i18nify";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import { getUrlPath } from "../../translations/translation";

export class Nav extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hideMenuMobile: true,
            hideMenuMobileIsClicked: false,
            alertOpen: false,
            scrollPosition: 0,
        };
        this.closeMenu = this.closeMenu.bind(this);
    }

    openAlert() {
        this.setState({ alertOpen: true });
    }

    closeAlert() {
        this.setState({ alertOpen: false });
    }

    closeMenu() {
        this.setState({ hideMenuMobile: true });
    }

    menu = [
        {
            name: "home.navBar.news",
            href: getUrlPath() + "news",
        },
        {
            name: "home.navBar.offer",
            href: getUrlPath() + "offer",
        },
        {
            name: "home.navBar.projects",
            href: getUrlPath() + "projects",
        },
    ];

    componentDidMount() {
        const logoImg = document.getElementById("logo-img");
        const navbar = document.getElementById("navbar");
        const menuMobileIcon = document.getElementById("menu-mobile-icon");

        const scrollButton = document.getElementById("scroll-top-button");

        let prevScrollpos = window.pageYOffset;
        window.onscroll = (e) => {
            let currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos) {
                navbar.style.top = "16px";
                scrollButton.style.bottom = "30px";
            } else {
                navbar.style.top = "-250px";
                scrollButton.style.bottom = "-250px";
            }

            if (document.documentElement.scrollTop > 90) {
                navbar.classList.add("nav-white");
                logoImg.src = "/images/nav/azi-logo-blue.svg";
                menuMobileIcon.src = "/icons/shared/menu-icon-blue.svg";
            } else {
                scrollButton.style.bottom = "-250px";
                navbar.classList.remove("nav-white");
                logoImg.src = "/images/nav/azi-logo-white.svg";
                menuMobileIcon.src = "/icons/shared/menu-icon-white.svg";
            }
            if (window.pageYOffset - this.state.scrollPosition > 50) {
                this.setState({ hideMenuMobile: true });
            } else {
                if (prevScrollpos > currentScrollPos) {
                    this.setState({ scrollPosition: window.pageYOffset });
                }
            }

            prevScrollpos = currentScrollPos;
        };
    }

    render() {
        return (
            <>
                <nav id="navbar">
                    <div className="logo">
                        <Link to={getUrlPath()}>
                            <img
                                id="logo-img"
                                className="logo-img"
                                src="/images/nav/azi-logo-white.svg"
                                alt="azimuthe white logo"
                            />
                        </Link>
                    </div>
                    <div className="nav-list-container">
                        <ul id="menu-items-id" className="menu-items">
                            {this.menu.map((item, index) => (
                                <li key={item.name}>
                                    <NavLink
                                        to={item.href}
                                        id={"menu-item-" + index}
                                        className={(navData) => (navData.isActive ? "active-route" : 'none')}
                                    >
                                        <Translate value={item.name} />
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="language-go-to-app-button">
                        <PositionedMenu className="language" />

                        <div
                            id="open-menu-icon"
                            className="menu-icon"
                            onClick={() => {
                                this.setState({
                                    scrollPosition: window.pageYOffset,
                                    hideMenuMobile: false,
                                    hideMenuMobileIsClicked: true,
                                });
                            }}
                        >
                            <img
                                id="menu-mobile-icon"
                                src="/icons/shared/menu-icon-white.svg"
                                alt="menu mobile icon"
                            />
                        </div>
                        <div className="buttons-container">
                            <button
                                rel="nofollow"
                                href="https://app.azimuthe.com/sign-up"
                                target="blank"
                                className="go-to-app-button azi-button azi-button-primary azi-button-disabled-white"
                            >
                                <Translate value="home.navBar.goToSignUp" />
                            </button>
                            <button
                                rel="nofollow"
                                href="https://app.azimuthe.com/sign-in"
                                target="blank"
                                className="go-to-app-button azi-button azi-button-primary azi-button-disabled-white"
                            >
                                <Translate value="home.navBar.goToApp" />
                            </button>
                        </div>
                    </div>
                </nav>

                <div
                    id="mobile-menu"
                    className={classNames({
                        "menu-mobile animate__animated animate__slideInDown":
                            !this.state.hideMenuMobile,
                        "menu-mobile hide-mobile-menu":
                            this.state.hideMenuMobile &&
                            this.state.hideMenuMobileIsClicked,
                        "disp-none": !this.state.hideMenuMobileIsClicked,
                    })}
                >
                    <div className="close-menu-mobile-icon">
                        <div
                            onClick={() =>
                                this.setState({ hideMenuMobile: true })
                            }
                        ></div>
                    </div>
                    <ul className="menu-mobile-items">
                        {this.menu.map((item) => (
                            <li key={item.name}>
                                <Link to={item.href}>
                                    <Translate value={item.name} />
                                </Link>
                                <hr className="strip"></hr>
                            </li>
                        ))}
                    </ul>
                    <div className="menu-mobile-language-button">
                        <PositionedMenu className="language" />
                    </div>
                    <div className="menu-mobile-button-container flex-row flex-justify-content-center width-100">
                        <div
                            onClick={() => this.openAlert()}
                            className="azi-button azi-button-primary  width-80"
                        >
                            <Translate value="home.navBar.goToApp" />
                        </div>
                    </div>

                    <Collapse
                        in={this.state.alertOpen}
                        className="alert-collapse"
                    >
                        <Alert className="alert-container" severity="info">
                            <div>
                                <AlertTitle className="alert-title">
                                    <Translate value="home.startSection.appOpenWarning" />
                                </AlertTitle>
                            </div>
                            <div className="alert-button-container">
                                <div
                                    className="azi-button azi-button-primary"
                                    onClick={() => this.closeAlert()}
                                >
                                    <Translate value="home.contactSection.alertClose" />
                                </div>
                            </div>
                        </Alert>
                    </Collapse>
                </div>
                <div
                    id="scroll-top-button"
                    className="scroll-top-button"
                    onClick={() =>
                        window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                >
                    <img
                        className="arrow-up"
                        src="/icons/shared/arrow-down-2.svg"
                        alt="arrow down icon"
                    />
                </div>
            </>
        );
    }
}

export default Nav;
