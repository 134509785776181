import "./azi-price-list.scss?inline";
import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { Translate } from "react-i18nify";
import classNames from "classnames";

export class AziPriceList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            anchorEl: null,
            currencies: [
                {
                    name: "PLN",
                    sign: "zł",
                    course: 1.0,
                },
                {
                    name: "EUR",
                    sign: "€",
                    course: 4.76,
                },
                {
                    name: "USD",
                    sign: "$",
                    course: 4.43,
                },
                {
                    name: "CHF",
                    sign: "fr",
                    course: 4.82,
                },
            ],
            currentCurrency: {
                name: "PLN",
                sign: "zł",
            },
            plnPrices: [0, 1000, 3000, 4000, 9000, 15000],
            prices: [0, 1000, 3000, 4000, 9000, 15000],
        };
    }

    openMenu() {
        document.getElementById("currency-menu-button").style.transform =
            "rotate(180deg)";
        this.setState({
            menuOpen: true,
            anchorEl: document.getElementById("menu-button"),
        });
    }

    closeMenu() {
        document.getElementById("currency-menu-button").style.transform =
            "rotate(0deg)";
        this.setState({
            menuOpen: false,
        });
    }

    setCurrency(currency) {
        this.setState({
            currentCurrency: {
                name: currency.name,
                sign: currency.sign,
            },
        });
        this.calculatePrices(currency);
        this.closeMenu();
    }

    calculatePrices(currency) {
        if (currency.name === "PLN") {
            this.setState({
                prices: this.state.plnPrices,
            });
        } else {
            let newPrices = [];
            this.state.plnPrices.forEach((price) => {
                newPrices.push(Math.round(price * (1 / currency.course)));
            });
            this.setState({
                prices: newPrices,
            });
        }
    }

    render() {
        return (
            <>
                <div className="bgc-section">
                    <div className="azi-price-list-section section-header-sub-page-spacer ">
                        <h1
                            data-aos="fade-right"
                            className="azi-price-list-header section-header"
                        >
                            <Translate value="offer.priceListSection.header" />
                        </h1>
                        <div className="azi-price-list-container">
                            <div className="azi-price-list-basic-explanation">
                                <div
                                    data-aos="fade-right"
                                    className="explanation-photo"
                                >
                                    <picture>
                                        <source
                                            srcSet="/images/azi-offer-page-section/offer-pen.webp"
                                            type="image/webp"
                                        />
                                        <img
                                            src="/images/azi-offer-page-section/offer-pen.png"
                                            alt="pen icon"
                                        />
                                    </picture>
                                </div>
                                <div
                                    data-aos="fade-left"
                                    className="explanation-text-container"
                                >
                                    <div className="explanation-header">
                                        <Translate value="offer.priceListSection.basicPackageTitle" />
                                    </div>
                                    <div className="explanation-desc">
                                        <Translate value="offer.priceListSection.basicPackageDescriptionPart1" />
                                        <span className="highlight-text-light">
                                            {" "}
                                            <Translate value="offer.priceListSection.basicPackageDescriptionHighlight" />{" "}
                                        </span>
                                        <Translate value="offer.priceListSection.basicPackageDescriptionPart2" />
                                    </div>
                                </div>
                            </div>

                            <div
                                data-aos="fade-up"
                                className="azi-price-list-table"
                            >
                                <div className="col-1">
                                    <Translate value="offer.priceListSection.packageCardCount" />
                                </div>
                                <div className="col-2">
                                    <Translate value="offer.priceListSection.packagePrice" />
                                </div>
                                <div className="col-3">
                                    <div
                                        id="menu-button"
                                        onClick={() => this.openMenu()}
                                    >
                                        {this.state.currentCurrency.name}
                                        <img
                                            id="currency-menu-button"
                                            className="menu-button-icon"
                                            src="/icons/shared/chevron-down-primary.svg"
                                            alt="arrow down icon"
                                        />
                                    </div>
                                    <Menu
                                        open={this.state.menuOpen}
                                        onClose={() => this.closeMenu()}
                                        anchorEl={this.state.anchorEl}
                                        PaperProps={{
                                            style: { borderRadius: 20 },
                                        }}
                                    >
                                        {this.state.currencies.map((item) => {
                                            return (
                                                <MenuItem
                                                    key={item.name}
                                                    className={classNames({
                                                        "currency-menu-item": true,
                                                        "currency-menu-item-selected":
                                                            this.state
                                                                .currentCurrency
                                                                .name ===
                                                            item.name,
                                                    })}
                                                    onClick={() =>
                                                        this.setCurrency(item)
                                                    }
                                                >
                                                    <div>{item.sign}</div>
                                                    <div>{item.name}</div>
                                                </MenuItem>
                                            );
                                        })}
                                    </Menu>
                                </div>
                                <div className="free-text">
                                    <p>FREE</p>
                                </div>
                                <div className="line-break"></div>
                                <div className="row-1">
                                    <div className="value-1">BASIC</div>
                                    <div className="value-2">10</div>
                                    <div className="value-3">
                                        {this.state.prices[0]}{" "}
                                        {this.state.currentCurrency.sign}
                                    </div>
                                </div>
                                <div className="row-2">
                                    <div className="value-1">
                                        <div className="value-1-1">BASIC</div>
                                        <div className="value-1-2">200</div>
                                    </div>
                                    <div className="value-2">11 - 20</div>
                                    <div className="value-3">
                                        {this.state.prices[1]}{" "}
                                        {this.state.currentCurrency.sign}
                                    </div>
                                </div>
                                <div className="row-3">
                                    <div className="value-1">
                                        <div className="value-1-1">BASIC</div>
                                        <div className="value-1-2">300</div>
                                    </div>
                                    <div className="value-2">21 - 50</div>
                                    <div className="value-3">
                                        {this.state.prices[2]}{" "}
                                        {this.state.currentCurrency.sign}
                                    </div>
                                </div>
                                <div className="row-4">
                                    <div className="value-1">
                                        <div className="value-1-1">BASIC</div>
                                        <div className="value-1-2">400</div>
                                    </div>
                                    <div className="value-2">51 - 100</div>
                                    <div className="value-3">
                                        {this.state.prices[3]}{" "}
                                        {this.state.currentCurrency.sign}
                                    </div>
                                </div>
                                <div className="row-5">
                                    <div className="value-1">
                                        <div className="value-1-1">BASIC</div>
                                        <div className="value-1-2">900</div>
                                    </div>
                                    <div className="value-2">101 - 500</div>
                                    <div className="value-3">
                                        {this.state.prices[4]}{" "}
                                        {this.state.currentCurrency.sign}
                                    </div>
                                </div>
                                <div className="row-6">
                                    <div className="value-1">
                                        <div className="value-1-1">BASIC</div>
                                        <div className="value-1-2">
                                            UNLIMITED
                                        </div>
                                    </div>
                                    <div className="value-2">
                                        {" "}
                                        <Translate value="offer.priceListSection.packagePriceUnlimited" />
                                    </div>
                                    <div className="value-3">
                                        {this.state.prices[5]}{" "}
                                        {this.state.currentCurrency.sign}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AziPriceList;
