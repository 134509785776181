import { Alert, AlertTitle, Collapse } from "@mui/material";
import React from "react";
import { getLocale } from "react-i18nify";
import { setLang } from "../../translations/translation";
import "./language-snackbar.scss";

export default class LanguageSnackbar extends React.PureComponent {

    constructor(props){
        super(props);
        this.state = {
            alertOpen: false,
            alertTitle: '',
            alertText: '',
            alertButton: '',
            alertLang: ''
        }
    }
   
    allertLangs = [
        {
            lang: 'pl',
            allertTitle: 'Ta strona jest dostępna również w języku polskim',
            allertText: "Jeśli zechcesz, możesz przeglądać tę stronę w Twoim języku",
            allertButton: "Zmień język",
            alertFlag: '/icons/flags/polish-flag.svg'
        },
        {
            lang: 'es',
            allertTitle: 'Esta página también está disponible en inglés',
            allertText: "Si lo desea, puede navegar por este sitio en su idioma",
            allertButton: "Cambiar de idioma",
            alertFlag: '/icons/flags/spanish-flag.svg'
        },
        {
            lang: 'en',
            allertTitle: 'This page is also available in English',
            allertText: "If you wish, you can browse this site in your language",
            allertButton: "Change language",
            alertFlag: '/icons/flags/english-flag.svg'
        },
        {
            lang: 'de',
            allertTitle: 'Diese Seite ist auch auf Englisch verfügbar',
            allertText: "Wenn Sie möchten, können Sie diese Website in Ihrer Sprache durchsuchen",
            allertButton: "Sprache ändern",
            alertFlag: '/icons/flags/german-flag.svg'
        },
        {
            lang: 'ua',
            allertTitle: 'Ця сторінка також доступна англійською мовою',
            allertText: "За бажанням ви можете переглянути цей сайт вашою мовою",
            allertButton: "Змінити мову",
            alertFlag: '/icons/flags/ukrainian-flag.svg'
        },

    ]
    browserLang = navigator.language.slice(0, 2);
    currentLang = getLocale();

    componentDidMount(){
        if(this.browserLang !== this.currentLang && (this.browserLang === 'pl' || this.browserLang === 'en' || this.browserLang === 'es' || this.browserLang === 'de' || this.browserLang === 'ua')){
            const index = this.allertLangs.findIndex(object => {
                return object.lang === this.browserLang;
              });
            this.setState({
                alertTitle: this.allertLangs[index].allertTitle,
                alertText:  this.allertLangs[index].allertText,
                alertButton: this.allertLangs[index].allertButton,
                alertLang: this.allertLangs[index].lang,
                alertFlag: this.allertLangs[index].alertFlag
            })
            setTimeout(() => {
                this.setState({alertOpen: true})
            }, 3000)
            setTimeout(() => {
                this.setState({alertOpen: false});
            }, 15000)
        }
    }

    render(){
        return (
            <div className="language-snackbar-constainer">
                <Collapse className="alert-collapse" in={this.state.alertOpen}>
                    <Alert className="alert-container" severity="info">
                        <div>
                            <AlertTitle className="alert-title">{this.state.alertTitle} <img src={this.state.alertFlag} alt="" /> </AlertTitle>
                        </div>
                        <div className="alert-button-container">
                            <div
                                className="azi-button azi-button-primary"
                                onClick={() => {
                                    this.setState({alertOpen: false});
                                    setLang(this.state.alertLang);
                                }}>
                                {this.state.alertButton}
                            </div>
                        </div>
                    </Alert>
                </Collapse>
            </div>
        );
    }
  
}
