import "./App.scss";
import Home from "./pages/home";
import Offer from "./pages/offer/offer";
import Projects from "./pages/projects/projects";
import News from "./pages/news/news";
import translations, { getUrlPath } from "./translations/translation";
import SpecyficNews from "./pages/news/specyfic-news/specyfic-news";
import { NotFound } from "./pages/notFound/notFound";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import SpecyficNews2 from "./pages/news/specyfic-news/specyfic-news-2";

function App() {
    translations();

    return (
        <Router>
            <Routes>
                <Route
                    exact
                    path={getUrlPath()}
                    element={<Home />}
                ></Route>
                <Route
                    exact
                    path=""
                    element={<Navigate to={getUrlPath()} />}
                ></Route>
                <Route path="*" element={<NotFound />} />
                <Route
                    exact
                    path={getUrlPath() + "news"}
                    element={<News />}
                ></Route>
                <Route
                    exact
                    path="/news"
                    element={<Navigate to={getUrlPath() + "news"} />}
                ></Route>
                <Route
                    exact
                    path={getUrlPath() + "news/news-1"}
                    element={<SpecyficNews />}
                ></Route>
                <Route
                    exact
                    path="/news/news-1"
                    element={
                        <Navigate to={getUrlPath() + "news/news-1"} />
                    }
                ></Route>
                        <Route
                    exact
                    path={getUrlPath() + "news/news-gs1-certificate"}
                    element={<SpecyficNews2 />}
                ></Route>
                <Route
                    exact
                    path="/news/news-gs1-certificate"
                    element={
                        <Navigate to={getUrlPath() + "news/news-gs1-certificate"} />
                    }
                ></Route>
                <Route
                    exact
                    path={getUrlPath() + "offer"}
                    element={<Offer />}
                ></Route>
                <Route
                    exact
                    path="/offer"
                    element={<Navigate to={getUrlPath + "offer"} />}
                ></Route>
                <Route
                    exact
                    path={getUrlPath() + "projects"}
                    element={<Projects />}
                ></Route>
                <Route
                    exact
                    path="/projects"
                    element={<Navigate to={getUrlPath() + "projects"} />}
                ></Route>
            </Routes>
        </Router>
    );
}

export default App;
