import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { translate } from "react-i18nify";
import AziProjects from "../../components/azi-projects-page-sections/azi-projects";
import Footer from "../../components/footer/footer";
import HeaderSubPage from "../../components/header-subpage/header-subpage";
import Nav from "../../components/nav/nav";
import { getUrlPath } from "../../translations/translation";

function Projects() {
    window.scrollTo(0, 0);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{translate("metaData.projects.title")}</title>
                    <meta
                        property="og:title"
                        content={translate("metaData.projects.fbTitle")}
                    />
                    <meta
                        name="twitter:title"
                        content={translate("metaData.projects.twitterTitle")}
                    />
                    <meta
                        name="description"
                        content={translate("metaData.projects.description")}
                    />
                    <meta
                        property="og:description"
                        content={translate("metaData.projects.fbDescription")}
                    />
                    <meta
                        name="twitter:description"
                        content={translate(
                            "metaData.projects.twitterDescription"
                        )}
                    />
                    <link
                        rel="canonical"
                        href={"https://azimuthe.com" + getUrlPath() + "projects"}
                    />
                    <link
                        rel="alternate"
                        hreflang="pl"
                        href="https://azimuthe.com/pl/projects"
                    />
                    <link
                        rel="alternate"
                        hreflang="en"
                        href="https://azimuthe.com/projects"
                    />
                    <link
                        rel="alternate"
                        hreflang="es"
                        href="https://azimuthe.com/es/projects"
                    />
                    <link
                        rel="alternate"
                        hreflang="de"
                        href="https://azimuthe.com/de/projects"
                    />
                    <link
                        rel="alternate"
                        hreflang="uk"
                        href="https://azimuthe.com/ua/projects"
                    />
                </Helmet>

                <Nav />
                <HeaderSubPage text="home.navBar.projects" />
                <AziProjects />
                <div className="section-spacer"></div>
                <Footer />
            </HelmetProvider>
        </>
    );
}

export default Projects;
