import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { translate } from "react-i18nify";
import AziNews from "../../components/azi-news-page-section/azi-news/azi-news";
import Footer from "../../components/footer/footer";
import HeaderSubPage from "../../components/header-subpage/header-subpage";
import Nav from "../../components/nav/nav";
import { getUrlPath } from "../../translations/translation";

function News() {
    window.scrollTo(0, 0);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{translate("metaData.news.title")}</title>
                    <meta
                        property="og:title"
                        content={translate("metaData.news.fbTitle")}
                    />
                    <meta
                        name="twitter:title"
                        content={translate("metaData.news.twitterTitle")}
                    />
                    <meta
                        name="description"
                        content={translate("metaData.news.description")}
                    />
                    <meta
                        property="og:description"
                        content={translate("metaData.news.fbDescription")}
                    />
                    <meta
                        name="twitter:description"
                        content={translate("metaData.news.twitterDescription")}
                    />
                    <link
                        rel="canonical"
                        href={"https://azimuthe.com" + getUrlPath() + "news"}
                    />
                    <link
                        rel="alternate"
                        hreflang="pl"
                        href="https://azimuthe.com/pl/news"
                    />
                    <link
                        rel="alternate"
                        hreflang="en"
                        href="https://azimuthe.com/news"
                    />
                    <link
                        rel="alternate"
                        hreflang="es"
                        href="https://azimuthe.com/es/news"
                    />
                    <link
                        rel="alternate"
                        hreflang="de"
                        href="https://azimuthe.com/de/news"
                    />
                    <link
                        rel="alternate"
                        hreflang="uk"
                        href="https://azimuthe.com/ua/news"
                    />
                </Helmet>

                <Nav />
                <HeaderSubPage text="home.navBar.news" />
                <AziNews />
                <div className="section-spacer"></div>
                <Footer />
            </HelmetProvider>
        </>
    );
}

export default News;
