import "./azi-news.scss?inline";
import React from "react";
import { Translate } from "react-i18nify";
import { Link } from "react-router-dom";

export class AziNews extends React.PureComponent {
    newsStore = [
        {
            title: "specyficNews.gs1Cert.title",
            date: "17.03.2023 11:21",
            photo: "/images/azi-news-page-section/azimuthe-gs1-cert.png",
            url: "/news/news-gs1-certificate"
        },
        {
            title: "specyficNews.first.title",
            date: "05.01.2023 14:35",
            photo: "/images/azi-news-page-section/azimuthe-new-page-screen.png",
            url: "/news/news-1"
        },

        // {
        //     title: "Aktualizacja 1.1.1 - Zobacz listę najnowszych zmian, poprawek",
        //     date: "25.10.2022 15:49",
        //     photo: "/images/azi-news-page-section/3.jfif",
        //     url: "/news/news-3"

        // },
        // {
        //     title: "Aktualizacja 1.1.1",
        //     date: "25.10.2022 15:49",
        //     photo: "/images/azi-news-page-section/update.jpg",
        //     url: "/news/news-4"

        // },
        // {
        //     title: "Aktualizacja 1.1.1",
        //     date: "25.10.2022 15:49",
        //     photo: "/images/azi-news-page-section/curiosity.jpg",
        //     url: "/news/news-5"

        // },
        // {
        //     title: "Aktualizacja 1.1.1",
        //     date: "25.10.2022 15:49",
        //     photo: "/images/azi-news-page-section/post.jpg",
        //     url: "/news/news-5"

        // },
    ];

    render() {
        return (
            <>
                <div className="bgc-section">
                    <div className="azi-news-section section-header-sub-page-spacer">
                        <h1 data-aos="fade-right" className="section-header">
                            <Translate value="news.header" />
                        </h1>
                        <div data-aos="fade-up" data-aos-delay="300" className="azi-news-container">
                            {this.newsStore.map((item, i) => (
                                <Link
                                    key={i}
                                    className="azi-news-element"
                                    to={item.url}
                                >
                                    <img src={item.photo} alt="representation what the actuality is about" />
                                    <div className="azi-inner-element"></div>
                                    <div className="azi-news-element-text">
                                        <span className="text">
                                            <Translate value={item.title} />
                                        </span>
                                        <span className="date">
                                            {item.date}
                                        </span>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        <div className="azi-news-socials">
                            <p>
                                <Translate value="news.followUs" />
                            </p>
                            <div className="azi-news-socials-container">
                                {/* <img
                                    src="/icons/socials/socials-twitter-blue.svg"
                                    alt="twitter icon"
                                /> */}
                                <a rel="nofollow" href="https://www.linkedin.com/company/azimuthe/" target="blank">
                                <img
                                    src="/icons/socials/socials-linkedin-blue.svg"
                                    alt="linkedin icon"
                                />
                                 </a>
                                {/* <img
                                    src="/icons/socials/socials-facebook-blue.svg"
                                    alt="facebook icon"
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </> 
        );
    }
}

export default AziNews;
