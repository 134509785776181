import "./azi-gallery.scss";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Translate } from "react-i18nify";
import { Link } from "react-router-dom";

class AziGallery extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            itemData: [
                {
                    img: "/images/azi-main-page-section/gallery-panel-glowny.webp",
                    imgPng: "/images/azi-main-page-section/gallery-panel-glowny-min.png",
                    title: "home.gallerySection.img1Title",
                    description: "home.gallerySection.img1Description",
                },
                {
                    img: "/images/azi-main-page-section/gallery-panel-organizacji.webp",
                    imgPng: "/images/azi-main-page-section/gallery-panel-organizacji-min.png",
                    title: "home.gallerySection.img2Title",
                    description: "home.gallerySection.img2Description",
                },
                {
                    img: "/images/azi-main-page-section/gallery-ustawienia-organizacji.webp",
                    imgPng: "/images/azi-main-page-section/gallery-ustawienia-organizacji-min.png",
                    title: "home.gallerySection.img3Title",
                    description: "home.gallerySection.img3Description",
                },
                {
                    img: "/images/azi-main-page-section/gallery-baza-kart.webp",
                    imgPng: "/images/azi-main-page-section/gallery-baza-kart-min.png",
                    title: "home.gallerySection.img4Title",
                    description: "home.gallerySection.img4Description",
                },
                {
                    img: "/images/azi-main-page-section/gallery-dodawanie-karty.webp",
                    imgPng: "/images/azi-main-page-section/gallery-dodawanie-karty-min.png",
                    title: "home.gallerySection.img5Title",
                    description: "home.gallerySection.img5Description",
                },
                {
                    img: "/images/azi-main-page-section/gallery-formularz-karty.webp",
                    imgPng: "/images/azi-main-page-section/gallery-formularz-karty-min.png",
                    title: "home.gallerySection.img6Title",
                    description: "home.gallerySection.img6Description",
                },
            ],
        };
    }

    setDialogOpen(isOpen) {
        this.setState({
            dialogOpen: isOpen,
        });
    }

    setDialogClose(isOpen) {
        this.setState({
            dialogOpen: isOpen,
        });
    }

    render() {
        return (
            <>
                <div className="azi-gallery-section section-padding">
                    <h2 data-aos="fade-right" className="section-header">
                        <Translate value="home.gallerySection.header" />
                    </h2>
                    <div data-aos="fade-right" className="sub-header">
                        <Translate value="home.gallerySection.subHeader" />
                        <span className="highlight-text">
                            <Translate value="home.gallerySection.subHeaderHighlight" />
                        </span>
                        <Translate value="home.gallerySection.subHeaderPart2" />
                    </div>
                    <div className="azi-gallery-container">
                        <picture>
                            <source
                                srcSet="/images/azi-main-page-section/laptop-min.webp"
                                type="image/webp"
                            />
                            <img
                                data-aos="fade-right"
                                src="/images/azi-main-page-section/laptop-min.png"
                                alt="laptop azimuthe screen"
                                loading="lazy"
                            />
                        </picture>
                        <picture>
                            <source
                                srcSet="/images/azi-main-page-section/desktop-min.webp"
                                type="image/webp"
                            />
                            <img
                                data-aos="fade-left"
                                src="/images/azi-main-page-section/desktop-min.png"
                                alt="desktop azimuthe screen"
                                loading="lazy"
                            />
                        </picture>
                    </div>
                    <div data-aos="fade-up" className="button-container">
                        <div className="button-text">
                            <Translate value="home.gallerySection.knowMoreQuestion" />
                        </div>
                        <div
                            onClick={() => this.setDialogOpen(true)}
                            className="azi-button azi-button-primary"
                        >
                            <Translate value="home.gallerySection.knowMore" />
                        </div>
                    </div>
                </div>
                <Dialog
                    PaperProps={{
                        style: { borderRadius: 25 },
                    }}
                    maxWidth="lg"
                    open={this.state.dialogOpen}
                >
                    <DialogContent className="dialog-background">
                        <div>
                            <div>
                                <div className="gallery-header">
                                    <Translate value="home.gallerySection.modalHeaderTextPart1" />
                                    <Link target="_blank" to="/offer">
                                        <span className="highlight-text">
                                            <Translate value="home.gallerySection.modalHeaderTextHighlight" />
                                        </span>
                                    </Link>{" "}
                                    <Translate value="home.gallerySection.modalHeaderTextPart2" />
                                </div>
                            </div>
                            <ImageList cols={1}>
                                {this.state.itemData.map((item) => (
                                    <ImageListItem key={item.img}>
                                        <div data-aos="fade-in" data-aos-delay="300" data-aos-duration="1000" className="image-element-container">
                                            <div className="image-description-container">
                                                <div className="image-title">
                                                    <Translate
                                                        value={item.title}
                                                    />
                                                </div>
                                                <div className="image-description">
                                                    <Translate
                                                        value={item.description}
                                                    />
                                                </div>
                                            </div>
                                            <picture className="gallery-modal-container">
                                                <source
                                                    srcSet={item.img}
                                                    type="image/webp"
                                                />
                                                <img
                                                
                                                    className="gallery-image"
                                                    src={item.imgPng}
                                                    alt="azimuthe gallery element"
                                                    
                                                />
                                            </picture>
                                        </div>
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </div>
                        <div className="close-button-container">
                            <div
                                className="azi-button azi-button-primary"
                                onClick={() => this.setDialogClose(false)}
                                autoFocus
                            >
                                <Translate value="home.contactSection.alertClose" />
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default AziGallery;
