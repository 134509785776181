import "./footer.scss";
import React from "react";
import { Translate } from "react-i18nify";

class Footer extends React.PureComponent {
    render() {
        return (
            <>
                <footer>
                    <div className="footer-container">
                        <div className="footer-elements-container">
                            <div className="address">
                                <img
                                    src="/images/footer/azimuthe-full-logo-white.svg"
                                    alt="azimuthe full white logo"
                                />
                                <p>Azimuthe Sp. z o.o.</p>
                                <p>ul. Kijowska 44</p>
                                <p>85-705 Bydgoszcz</p>
                                <br />
                                <p>VAT ID/NIP: PL5542985851</p>
                                <p>KRS: 0000844152</p>
                                <p>REGON: 386206047</p>
                            </div>
                            <div className="services">
                                <div className="services-header">
                                    <Translate value="home.footer.services.header" />
                                </div>
                                <p>
                                    <Translate value="home.footer.services.productData" />
                                </p>
                                <p>
                                    <Translate value="home.footer.services.dataLoadEtl" />
                                </p>
                                <p>
                                    <Translate value="home.footer.services.dataClasification" />
                                </p>
                                <p>
                                    <Translate value="home.footer.services.dataModel" />
                                </p>
                                <p>
                                    <Translate value="home.footer.services.qualityControl" />
                                </p>

                                <p>
                                    <Translate value="home.footer.services.richContent" />
                                </p>
                            </div>
                            <div className="platform">
                                <div className="platform-header">
                                    <Translate value="home.footer.platform.header" />
                                </div>
                                <a
                                    rel="nofollow"
                                    href="https://storage.googleapis.com/azimuthe-static/docs/AZIMUTHE%20regulamin%20Serwis%20v.%202.4.pdf"
                                    target="blank"
                                >
                                    <Translate value="home.footer.platform.regulations" />
                                </a>
                                <a
                                    rel="nofollow"
                                    href="https://storage.googleapis.com/azimuthe-static/docs/Cennik_za%C5%82%C4%85cznik_nr_1.pdf"
                                    target="blank"
                                >
                                    <Translate value="home.footer.platform.priceList" />
                                </a>
                                <a
                                    rel="nofollow"
                                    href="https://storage.googleapis.com/azimuthe-static/docs/AZIMUTHE%20polityka%20prywatnos%CC%81ci%20Serwis%20v.%202.1.pdf"
                                    target="blank"
                                >
                                    <Translate value="home.footer.platform.privacy" />
                                </a>
                                <a
                                    rel="nofollow"
                                    href="https://azimuthe.com/media/Certyfikat_Zgody z GS1 Azimuthe 2023.pdf"
                                    target="blank"
                                >
                                    <Translate value="home.footer.platform.cert" />
                                </a>
                            </div>
                            <div className="contact">
                                <div className="contact-header">
                                    <Translate value="home.footer.contact" />
                                </div>
                                <p>hello@azimuthe.com</p>
                                <p>+48 510 022 035</p>
                                <div className="socials-container">
                                    {/* <img
                                        src="/icons/socials/socials-instagram.svg"
                                        alt=""
                                    />
                                    <img
                                        src="/icons/socials/socials-tiktok.svg"
                                        alt=""
                                    /> */}
                                    {/* <img
                                        src="/icons/socials/socials-twitter.svg"
                                        alt="twitter logo icon"
                                    /> */}
                                    <a
                                        rel="nofollow"
                                        href="https://www.linkedin.com/company/azimuthe/"
                                        target="blank"
                                    >
                                        <img
                                            src="/icons/socials/socials-linkedin.svg"
                                            alt="linkedin logo icon"
                                        />
                                    </a>
                                    {/* <img
                                        src="/icons/socials/socials-facebook.svg"
                                        alt="facebook logo icon"
                                    /> */}
                                </div>
                            </div>
                        </div>
                        <div className="footer-photo">
                            <img
                                src="/images/footer/fundusze-europejskie-stopka.svg"
                                alt="fundusze europejskie stopka"
                            />
                        </div>
                        <div className="footer-text">
                            © 2023 Azimuthe.{" "}
                            <Translate value="home.footer.rights" />
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer;
