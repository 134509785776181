import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { setLocale, getLocale } from "react-i18nify";
import "./positionedMenu.scss";
import classNames from "classnames";
import { getUrlPath } from "../../translations/translation";

export default function PositionedMenu() {
    const [currentLang, setCurrentLang] = React.useState(
        getLocale().toUpperCase()
    );
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const setLang = (lang) => {
        const prevLang = getLocale()
        setLocale(lang);
        setCurrentLang(lang.toUpperCase());
        localStorage.setItem("lang", lang);
        if (lang === "en") {
            window.history.replaceState(
                null,
                "",
                `${getUrlPath()}${window.location.pathname.slice(4)}`
            );
        }
        else if (prevLang  === 'en'){
            window.history.replaceState(
                null,
                "",
                `/${lang}${window.location.pathname}`
            );
        }
         else {
            window.history.replaceState(
                null,
                "",
                `/${lang}${window.location.pathname.slice(3)}`
            );
        }
        window.location.reload();
    };

    return (
        <>
            <div
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="language"
            >
                <div className="language-container">
                    <div className="language-text">{currentLang}</div>
                    <img
                        className="language-chevron"
                        src="/icons/shared/chevron-down.svg"
                        alt="arrow down icon"
                    />
                </div>
            </div>
            <Menu
                id="positioned-menu"
                anchorEl={anchorEl}
                disableAutoFocusItem={true}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: { borderRadius: 20 },
                }}
            >
                <MenuItem
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "185px",
                        color: "#083a5e",
                    }}
                    className={classNames({
                        "current-selected-element": currentLang === "PL",
                    })}
                    onClick={() => {
                        handleClose();
                        setLang("pl");
                    }}
                >
                    <div>Polski</div>
                    <img
                        style={{ width: "25px", height: "25px" }}
                        src="/icons/flags/polish-flag.svg"
                        alt="polish flag"
                    />
                </MenuItem>
                <MenuItem
                    sx={{
                        display: "flex",
                        width: "185px",
                        justifyContent: "space-between",
                        color: "#083a5e",
                    }}
                    className={classNames({
                        "current-selected-element": currentLang === "EN",
                    })}
                    onClick={() => {
                        handleClose();
                        setLang("en");
                    }}
                >
                    <div className="language-element-text">English</div>
                    <img
                        style={{ width: "25px", height: "25px" }}
                        src="/icons/flags/english-flag.svg"
                        alt="english flag"
                    />
                </MenuItem>
                <MenuItem
                    sx={{
                        display: "flex",
                        width: "185px",
                        justifyContent: "space-between",
                        color: "#083a5e",
                    }}
                    className={classNames({
                        "current-selected-element": currentLang === "ES",
                    })}
                    onClick={() => {
                        handleClose();
                        setLang("es");
                    }}
                >
                    <div>Español</div>
                    <img
                        style={{ width: "25px", height: "25px" }}
                        src="/icons/flags/spanish-flag.svg"
                        alt="spanish flag"
                    />
                </MenuItem>
                <MenuItem
                    sx={{
                        display: "flex",
                        width: "185px",
                        justifyContent: "space-between",
                        color: "#083a5e",
                    }}
                    className={classNames({
                        "current-selected-element": currentLang === "DE",
                    })}
                    onClick={() => {
                        handleClose();
                        setLang("de");
                    }}
                >
                    <div>Deutsch</div>
                    <img
                        style={{ width: "25px", height: "25px" }}
                        src="/icons/flags/german-flag.svg"
                        alt="german flag"
                    />
                </MenuItem>
                <MenuItem
                    sx={{
                        display: "flex",
                        width: "185px",
                        justifyContent: "space-between",
                        color: "#083a5e",
                    }}
                    className={classNames({
                        "current-selected-element": currentLang === "UA",
                    })}
                    onClick={() => {
                        handleClose();
                        setLang("ua");
                    }}
                >
                    <div>Yкраїнський</div>
                    <img
                        style={{ width: "25px", height: "25px" }}
                        src="/icons/flags/ukrainian-flag.svg"
                        alt="ukrainian flag"
                    />
                </MenuItem>
            </Menu>
        </>
    );
}
