import "./azi-start.scss";
import React from "react";
import { Translate } from "react-i18nify";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";

class AziStart extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            alertOpen: false,
        };
    }

    openAlert(){
        this.setState({alertOpen:true})
    }

    closeAlert(){
        this.setState({alertOpen:false})
    }

    render() {
        return (
            <>
                <div className="azi-start-section section-padding">
                    <h2
                        data-aos="fade-right"
                        data-aos-duration="1000"
                        className="azi-start-header text-gradient"
                    >
                        <Translate value="home.startSection.header" />
                    </h2>
                    <div className="azi-start-container">
                        <div className="azi-start-elements-container">
                            <div
                                data-aos="fade-right"
                                data-aos-duration="800"
                                className="azi-start-element"
                            >
                                <div className="azi-start-element-counter">
                                    1
                                </div>
                                <div className="azi-start-element-text .text-gradient">
                                    <Translate value="home.startSection.tutorial.step1" />
                                </div>
                            </div>
                            <div
                                data-aos="fade-right"
                                data-aos-duration="800"
                                data-aos-delay="350"
                                className="azi-start-element"
                            >
                                <div className="azi-start-element-counter">
                                    2
                                </div>
                                <div className="azi-start-element-text">
                                    <Translate value="home.startSection.tutorial.step2" />
                                </div>
                            </div>
                            <div
                                data-aos="fade-right"
                                data-aos-duration="800"
                                data-aos-delay="700"
                                className="azi-start-element"
                            >
                                <div className="azi-start-element-counter">
                                    3
                                </div>
                                <div className="azi-start-element-text">
                                    <Translate value="home.startSection.tutorial.step3" />
                                </div>
                            </div>
                        </div>
                        <div
                            data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-offset="20"
                            className="azi-start-text text-gradient-reverse"
                        >
                            <Translate value="home.startSection.subHeader" />
                        </div>
                        <a
                            rel="nofollow"
                            href="https://app.azimuthe.com/sign-up"
                            target="blank"
                            data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-offset="20"
                            className="azi-start-button azi-button azi-button-primary"
                        >
                            <Translate value="home.startSection.registration" />
                        </a>
                        <div
                            className="azi-start-button-mobile azi-button azi-button-primary"
                            onClick={() => this.openAlert()}
                        >
                            <Translate value="home.startSection.registration" />
                        </div>
                        <Collapse
                            className="alert-collapse"
                            in={this.state.alertOpen}
                        >
                            <Alert className="alert-container" severity="info">
                                <div>
                                    <AlertTitle className="alert-title">
                                        <Translate value="home.startSection.appOpenWarning" />
                                    </AlertTitle>
                                </div>
                                <div className="alert-button-container">
                                    <div
                                        className="azi-button azi-button-primary"
                                        onClick={() => this.closeAlert()}
                                    >
                                        <Translate value="home.contactSection.alertClose" />
                                    </div>
                                </div>
                            </Alert>
                        </Collapse>
                    </div>
                </div>
            </>
        );
    }
}
export default AziStart;
