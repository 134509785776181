import "./form-component.scss";
import React, { useCallback } from "react";
import { I18n, Translate, translate } from "react-i18nify";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import {
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

export default function FormComponent() {
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertType, setAlertType] = React.useState("success");
    const [topic, setTopic] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [content, setContent] = React.useState("");
    const [buttonLoading, setButtonLoading] = React.useState("");
    const [alertTitle, setAlertTitle] = React.useState("");
    const [alertText, setAlertText] = React.useState("");
    const { executeRecaptcha } = useGoogleReCaptcha();

 


    const handleTopic = (event) => {
        const target = event.target;
        setTopic(target.value)
    };

    const handleEmail = (event) => {
        const target = event.target;
        setEmail(target.value)
    };

    const handleContent = (event) => {
        const target = event.target;
        setContent(target.value)
    };


    const closeAlert = () => {
        setAlertOpen(false);
        document.querySelector("button").classList.remove("fail");
        document.querySelector("button").classList.remove("ready");
    };

    const sendEmail = useCallback(async () => {
        document.querySelector("button").classList.remove("fail");
        document.querySelector("button").classList.remove("ready");
        fetch(process.env.REACT_APP_MAIL_SEND, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({
                title: topic,
                content: content,
                email: email,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Something went wrong");
                }
            })
            .then((response) => {
                setButtonLoading(false);
                setAlertType("success");
                setAlertTitle("home.contactSection.alertTitleSuccess")
                setAlertText("home.contactSection.alertTextSuccess")
                setAlertOpen(true);
                document.querySelector("button").classList.add("ready");
            })
            .catch((error) => {
                setButtonLoading(false);
                setAlertType("error");
                setAlertTitle("home.contactSection.alertTitleError");
                setAlertText("home.contactSection.alertTextError")
                setAlertOpen(true);
                document.querySelector("button").classList.add("fail");
            });
    }, [content, email, topic]);

    const handleCaptcha = useCallback(async () => {
        setButtonLoading(true)
        if (!executeRecaptcha) {
            setButtonLoading(false);
            setAlertType("error");
            setAlertTitle("home.contactSection.alertTitleError");
            setAlertText("home.contactSection.alertTextError")
            setAlertOpen(true);
            document.querySelector("button").classList.add("fail");
            return;
        }
        const token = await executeRecaptcha('register');
  
    

        fetch(process.env.REACT_APP_CAPTCHA_VALIDATION, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                accept: "application/json",
            },
            body: JSON.stringify({
               recaptcha: token
            }),
        })
        .then((res) => sendEmail())
        .catch((error) => {console.log(error)})
    }, [executeRecaptcha, sendEmail]);

    return (
        <>
             
                    <div className="azi-contact-form-elements">
                        <div className="input-container">
                            <I18n
                                render={() => (
                                    <input
                                        name="topic"
                                        type="text"
                                        placeholder={translate(
                                            "home.contactSection.form.topic"
                                        )}
                                        value={topic}
                                        onChange={handleTopic}
                                    />
                                )}
                            />
                        </div>
                        <div className="input-container">
                            <I18n
                                render={() => (
                                    <input
                                        name="email"
                                        type="text"
                                        placeholder={translate(
                                            "home.contactSection.form.email"
                                        )}
                                        value={email}
                                        onChange={handleEmail}
                                    />
                                )}
                            />
                        </div>
                        <div className="input-container">
                            <I18n
                                render={() => (
                                    <textarea
                                        name="content"
                                        placeholder={translate(
                                            "home.contactSection.form.content"
                                        )}
                                        value={content}
                                        onChange={handleContent}
                                    ></textarea>
                                )}
                            />
                        </div>

                        <button
                            type="submit"
                            disabled={
                                topic === "" ||
                                email === "" ||
                                content === "" ||
                                buttonLoading === true
                            }
                            onClick={() => handleCaptcha()}
                        >
                            {buttonLoading ? (
                                <CircularProgress size={20} />
                            ) : (
                                <Translate value="home.contactSection.form.send" />
                            )}
                        </button>

                            
                  
                    </div>
                    <Collapse
                        className="alert-collapse"
                        in={alertOpen}
                    >
                        <Alert
                            className="alert-container"
                            severity={alertType}
                        >
                            <div>
                                <AlertTitle className="alert-title">
                                    <Translate value={alertTitle} />
                                </AlertTitle>
                            </div>
                            <Translate value={alertText} />
                            <span
                                className={classNames({
                                    "highlight-text": true,
                                    "disp-none":
                                        alertType === "success",
                                })}
                            >
                                -{" "}
                                <a href="mailto: hello@azimuthe.com">
                                    hello@azimuthe.com
                                </a>
                            </span>
                            <div className="alert-button-container">
                                <div
                                    className="azi-button azi-button-primary"
                                    onClick={() => closeAlert()}
                                >
                                    <Translate value="home.contactSection.alertClose" />
                                </div>
                            </div>
                        </Alert>
                    </Collapse>
             
        </>
    );
};
