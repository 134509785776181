import "./specyfic-news.scss";
import React from "react";
import Nav from "../../../components/nav/nav";
import HeaderSubPage from "../../../components/header-subpage/header-subpage";
import Footer from "../../../components/footer/footer";
import { Translate } from "react-i18nify";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { translate } from "react-i18nify";
import { getUrlPath } from "../../../translations/translation";

export class SpecyficNews2 extends React.PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <HelmetProvider>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{translate("metaData.news.specyfic-news.gs1Cert.title")}</title>
                        <meta
                            property="og:title"
                            content={translate("metaData.news.specyfic-news.gs1Cert.fbTitle")}
                        />
                        <meta
                            name="twitter:title"
                            content={translate("metaData.news.specyfic-news.gs1Cert.twitterTitle")}
                        />
                        <meta
                            name="description"
                            content={translate("metaData.news.specyfic-news.gs1Cert.description")}
                        />
                        <meta
                            property="og:description"
                            content={translate("metaData.news.specyfic-news.gs1Cert.fbDescription")}
                        />
                        <meta
                            name="twitter:description"
                            content={translate(
                                "metaData.news.twitterDescription"
                            )}
                        />
                        <link
                            rel="canonical"
                            href={
                                "https://azimuthe.com" + getUrlPath() + "news/news-gs1-certificate"
                            }
                        />
                        <link
                            rel="alternate"
                            hreflang="pl"
                            href="https://azimuthe.com/pl/news/news-gs1-certificate"
                        />
                        <link
                            rel="alternate"
                            hreflang="en"
                            href="https://azimuthe.com/news/news-gs1-certificate"
                        />
                        <link
                            rel="alternate"
                            hreflang="es"
                            href="https://azimuthe.com/es/news/news-gs1-certificate"
                        />
                        <link
                            rel="alternate"
                            hreflang="de"
                            href="https://azimuthe.com/de/news/news-gs1-certificate"
                        />
                        <link
                            rel="alternate"
                            hreflang="uk"
                            href="https://azimuthe.com/ua/news/news-gs1-certificate"
                        />
                    </Helmet>

                    <Nav />
                    <HeaderSubPage text="home.navBar.news" />
                    <div className="bgc-section">
                        <div className="specyfic-news-section">
                            <h1
                                data-aos="fade-right"
                                className="specyfic-news-section-header"
                            >
                                <Translate value="specyficNews.gs1Cert.title" />
                            </h1>
                            <p
                                data-aos="fade-right"
                                className="specyfic-news-date"
                            >
                                17.03.2023 11:21
                            </p>
                            <div
                                data-aos="fade-up"
                                className="specyfic-news-container"
                            >
                                {/* <h2 className="specyfic-news-header">
                                    <Translate value="specyficNews.gs1Cert.header" />
                                </h2> */}
                                <p className="specyfic-news-text">
                                    <Translate value="specyficNews.gs1Cert.text" />
                                </p>

                                {/* <p className="specyfic-news-text">
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Provident, ipsum porro. Minima
                                veritatis repudiandae consequuntur nostrum
                                perferendis dolore voluptates, ab quos saepe
                                cupiditate iure libero? Laborum quasi itaque id
                                consequatur!
                            </p> */}

                                {/* <p className="specyfic-news-text">
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Provident, ipsum porro. Minima
                                veritatis repudiandae consequuntur nostrum
                                perferendis dolore voluptates, ab quos saepe
                                cupiditate iure libero? Laborum quasi itaque id
                                consequatur! Lorem, ipsum dolor sit amet
                                consectetur adipisicing elit. Esse eaque,
                                dolorum cupiditate quibusdam quaerat dicta neque
                                cum illum facere nam dolore ex ullam maxime iste
                                adipisci possimus accusamus quisquam ea.
                            </p> */}
                                {/* <ul className="specyfic-news-list specyfic-news-text">
                                <li>
                                    {" "}
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit
                                </li>
                                <li>
                                    {" "}
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit
                                </li>
                                <li>
                                    {" "}
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit
                                </li>
                                <li>
                                    {" "}
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit
                                </li>
                                <li>
                                    {" "}
                                    Lorem ipsum dolor sit, amet consectetur
                                    adipisicing elit
                                </li> 
                            </ul> */}
                                {/* <p className="specyfic-news-text">
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Provident, ipsum porro. Minima
                                veritatis repudiandae consequuntur nostrum
                                perferendis dolore voluptates, ab quos saepe
                                cupiditate iure libero? Laborum quasi itaque id
                                consequatur! Lorem, ipsum dolor sit amet
                                consectetur adipisicing elit. Esse eaque,
                                dolorum cupiditate quibusdam quaerat dicta neque
                                cum illum facere nam dolore ex ullam maxime iste
                                adipisci possimus accusamus quisquam ea.
                            </p> */}
                                <div className="specyfic-news-img">
                                <img src="/images/azi-news-page-section/azimuthe-gs1-cert.png" alt="specyfic flag icon" />
                            </div>
                                {/* <p className="specyfic-news-text">
                                Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Provident, ipsum porro. Minima
                                veritatis repudiandae consequuntur nostrum
                                perferendis dolore voluptates, ab quos saepe
                                cupiditate iure libero? Laborum quasi itaque id
                                consequatur! Lorem, ipsum dolor sit amet
                                consectetur adipisicing elit. Esse eaque,
                                dolorum cupiditate quibusdam quaerat dicta neque
                                cum illum facere nam dolore ex ullam maxime iste
                                adipisci possimus accusamus quisquam ea.
                            </p> */}
                                <div className="spacer-38px"></div>
                                <Link
                                    to="/news"
                                    className="news-return-button azi-button azi-button-primary"
                                >
                                    <Translate value="404.backButton" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="sction-prim-white-spacer"></div>
                    <Footer />
                </HelmetProvider>
            </>
        );
    }
}

export default SpecyficNews2;
