import React from "react";
import Nav from "../components/nav/nav";
import Scheme from "../components/azi-main-page-sections/azi-scheme/scheme";
import AziObjectives from "../components/azi-main-page-sections/azi-objectives/azi-objectives";
import AziHeader from "../components/azi-main-page-sections/azi-header/header";
import AziContact from "../components/azi-main-page-sections/azi-contact/azi-contact";
import AziStart from "../components/azi-main-page-sections/azi-start/azi-start";
import AziPartners from "../components/azi-main-page-sections/azi-partners/azi-partners";
import Footer from "../components/footer/footer";
import AziBenefits from "../components/azi-main-page-sections/azi-benefits/azi-benefits";
import AziGallery from "../components/azi-main-page-sections/azi-gallery/azi-gallery";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { translate } from "react-i18nify";
import { getUrlPath } from "../translations/translation";
import LanguageSnackbar from "../components/language-snackbar/language-snackbar";

function Home() {
    window.scrollTo(0, 0);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{translate("metaData.home.title")}</title>
                    <meta
                        property="og:title"
                        content={translate("metaData.home.fbTitle")}
                    />
                    <meta
                        name="twitter:title"
                        content={translate("metaData.home.twitterTitle")}
                    />
                    <meta
                        name="description"
                        content={translate("metaData.home.description")}
                    />
                    <meta
                        property="og:description"
                        content={translate("metaData.home.fbDescription")}
                    />
                    <meta
                        name="twitter:description"
                        content={translate("metaData.home.twitterDescription")}
                    />
                    <link rel="canonical" href={"https://azimuthe.com" + getUrlPath()}/>
                    <link rel="alternate" hreflang="pl" href="https://azimuthe.com/pl/"/>
                    <link rel="alternate" hreflang="en" href="https://azimuthe.com/"/>
                    <link rel="alternate" hreflang="es" href="https://azimuthe.com/es/"/>
                    <link rel="alternate" hreflang="de" href="https://azimuthe.com/de/"/>
                    <link rel="alternate" hreflang="uk" href="https://azimuthe.com/ua/"/>


                </Helmet>

                <Nav />
                <AziHeader />
                <div className="spacer-40px"></div>
                <Scheme />
                <div className="section-spacer"></div>
                <AziObjectives />
                <div className="section-spacer"></div>
                <AziBenefits />
                <div className="section-spacer"></div>
                {/* gallery and start treated as one section */}
                <AziGallery />
                <AziStart />
                <div className="section-spacer"></div>
                <AziContact />
                <div className="section-spacer"></div>
                <AziPartners />
                <div className="section-spacer"></div>
                <Footer />
                <LanguageSnackbar />
            </HelmetProvider>
        </>
    );
}

export default Home;
