import "./azi-contact.scss";
import React from "react";
import { Translate } from "react-i18nify";
import FormComponent from "../../form-component/form-component";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

class AziContact extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            alertOpen: false,
            contacts: [
                {
                    photo: "/images/azi-main-page-section/contact-jacek.webp",
                    photoPng: "/images/azi-main-page-section/contact-jacek.png",
                    name: "Jacek Hęćka",
                    position: "CEO",
                    phone: "510 022 035",
                    mail: "jacek.hecka@azimuthe.com",
                },
                {
                    photo: "/images/azi-main-page-section/contact-roksana.webp",
                    photoPng:
                        "/images/azi-main-page-section/contact-roksana.png",
                    name: "Roksana Najdek",
                    position: "IT Project Manager",
                    phone: "609 116 682",
                    mail: "roksana.najdek@azimuthe.com",
                },
                {
                    photo: "/images/azi-main-page-section/contact-krzysztof.webp",
                    photoPng:
                        "/images/azi-main-page-section/contact-krzysztof.png",
                    name: "Krzysztof Justynowicz",
                    position: "Business Partner",
                    phone: "500 257 670",
                    mail: "k.justynowicz@azimuthe.com",
                },
            ],
            topic: "",
            email: "",
            content: "",
            alertTyoe: "success",
            alertTitle: "",
            alertText: "",
            buttonLoading: false,
        };

        // this.handleChange = this.handleChange.bind(this);
    }

    render() {
        return (
            <>
                <div className="azi-contact-section section-padding">
                    <h2
                        data-aos="fade-right"
                        className="section-header primary-background-section-header"
                    >
                        <Translate value="home.contactSection.header" />
                    </h2>
                    <div className="azi-contact-container">
                        <div
                            data-aos="fade-left"
                            className="azi-contact-box-one-container"
                        >
                            <div>
                                <div className="azi-contact-container-photo">
                                    <img
                                        src={"/images/nav/azi-logo-white.svg"}
                                        alt="azimuthe white logo"
                                    />
                                </div>
                                <div className="azi-contact-form-container">
                                    <div className="azi-contact-description">
                                        <Translate value="home.contactSection.form.header" />
                                    </div>
                                    <div className="azi-contact-form">
                                    <GoogleReCaptchaProvider
                                        reCaptchaKey={
                                            process.env.REACT_APP_CAPTCHA_KEY
                                        }
                                    >
                                        <FormComponent />
                                    </GoogleReCaptchaProvider>
                                    </div>
                                </div>
                            </div>

                            <div
                                data-aos="fade-up"
                                className="azi-contact-box-two-container"
                            >
                                <div className="azi-contact-description">
                                    <Translate value="home.contactSection.contactTilesHeader" />
                                </div>
                                <div className="azi-contact-elments-container">
                                    {this.state.contacts.map((item) => (
                                        <div
                                            key={item.name}
                                            data-aos="fade-up"
                                            className="azi-contact-element"
                                        >
                                            <div className="contact-element-photo">
                                                <picture>
                                                    <source
                                                        srcSet={item.photo}
                                                        type="image/webp"
                                                    />
                                                    <img
                                                        src={item.photoPng}
                                                        alt="azimuthe contact memoji"
                                                    />
                                                </picture>
                                            </div>
                                            <div className="contact-element-description">
                                                <div className="description-element-name">
                                                    {item.name}
                                                </div>
                                                <div className="description-element">
                                                    {item.position}
                                                </div>
                                                <div className="description-element">
                                                    <img
                                                        src="/icons/contact/phone-icon.svg"
                                                        alt="phone icon"
                                                    />
                                                    <div>{item.phone}</div>
                                                </div>
                                                <div className="description-element">
                                                    <img
                                                        src="/icons/contact/mail-icon.svg"
                                                        alt="mail icon"
                                                    />
                                                    <div>{item.mail}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AziContact;
