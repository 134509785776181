import "./azi-benefits.scss?inline";
import React from "react";
import { Translate } from "react-i18nify";

class AziBenefits extends React.PureComponent {
    render() {
        return (
            <>
                <div className="azi-benefits-section">
                    <h2
                        data-aos="fade-left"
                        className="section-header azi-benefits-header"
                    >
                        <Translate value="home.benefitsSection.header" />
                    </h2>

                    <div data-aos="fade-right" className="azi-benefits-element">
                        <div className="azi-benefits-element-image">
                            <picture>
                                <source
                                    srcSet="/icons/benefits/global-min.webp"
                                    type="image/webp"
                                />
                                <img
                                    src="/icons/benefits/global-min.png"
                                    alt="global-platform icon"
                                    loading="lazy"
                                />
                            </picture>
                        </div>

                        <div className="azi-benefits-element-text">
                            <div className="azi-benefits-element-title">
                                <Translate value="home.benefitsSection.global.title" />
                            </div>
                            <div className="azi-benefits-element-description">
                                <Translate value="home.benefitsSection.global.descriptionPart1" />
                                <span className="highlight-text-light">
                                    {" "}
                                    <Translate value="home.benefitsSection.global.descriptionHighlight" />{" "}
                                </span>
                                <Translate value="home.benefitsSection.global.descriptionPart2" />
                            </div>
                        </div>
                    </div>
                    <div
                        data-aos="fade-left"
                        className="azi-benefits-element azi-benefits-element-right"
                    >
                        <div className="azi-benefits-element-image">
                            <picture className="ml-img">
                                <source
                                    srcSet="/icons/benefits/ml-icon-01.webp"
                                    type="image/webp"
                                />
                                <img
                                    
                                    src="/icons/benefits/ml-icon-01.png"
                                    alt="machine learning icon"
                                    loading="lazy"
                                />
                            </picture>
                        </div>

                        <div className="azi-benefits-element-text">
                            <div className="azi-benefits-element-title">
                                <Translate value="home.benefitsSection.ml.title" />
                            </div>
                            <div className="azi-benefits-element-description">
                                <Translate value="home.benefitsSection.ml.descriptionPart1" />
                                <span className="highlight-text-light">
                                    {" "}
                                    <Translate value="home.benefitsSection.ml.descriptionHighlight" />{" "}
                                </span>
                                <Translate value="home.benefitsSection.ml.descriptionPart2" />
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-right" className="azi-benefits-element">
                        <div className="azi-benefits-element-image">
                            <picture>
                                <source
                                    srcSet="/icons/benefits/qc-min.webp"
                                    type="image/webp"
                                />
                                <img
                                    src="/icons/benefits/qc-min.png"
                                    alt="quality control icon"
                                    loading="lazy"
                                />
                            </picture>
                        </div>

                        <div className="azi-benefits-element-text">
                            <div className="azi-benefits-element-title">
                                <Translate value="home.benefitsSection.qc.title" />
                            </div>
                            <div className="azi-benefits-element-description">
                                <Translate value="home.benefitsSection.qc.descriptionPart1" />
                                <span className="highlight-text-light">
                                    {" "}
                                    <Translate value="home.benefitsSection.qc.descriptionHighlight" />{" "}
                                </span>
                                <Translate value="home.benefitsSection.qc.descriptionPart2" />
                            </div>
                        </div>
                    </div>
                    <div
                        data-aos="fade-left"
                        className="azi-benefits-element azi-benefits-element-right"
                    >
                        <div className="azi-benefits-element-image">
                            <picture>
                                <source
                                    srcSet="/icons/benefits/taxi-min.webp"
                                    type="image/webp"
                                />
                                <img
                                    src="/icons/benefits/taxi-min.png"
                                    alt="taxonomy icon"
                                    loading="lazy"
                                />
                            </picture>
                        </div>

                        <div className="azi-benefits-element-text">
                            <div className="azi-benefits-element-title">
                                <Translate value="home.benefitsSection.taxi.title" />
                            </div>
                            <div className="azi-benefits-element-description">
                                <Translate value="home.benefitsSection.taxi.descriptionPart1" />
                                <span className="highlight-text-light">
                                    {" "}
                                    <Translate value="home.benefitsSection.taxi.descriptionHighlight" />{" "}
                                </span>
                                <Translate value="home.benefitsSection.taxi.descriptionPart2" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AziBenefits;
