import "./notFound.scss";
import React from "react";
import { Translate } from "react-i18nify";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer from "../../components/footer/footer";
import HeaderSubPage from "../../components/header-subpage/header-subpage";

export class NotFound extends React.PureComponent {
    render() {
        return (
            <>
                <HelmetProvider>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Page not found</title>
                    </Helmet>
                    <HeaderSubPage text="404.404" />
                    <div className="not-found-section-container">
                        <div className="not-found-container">
                            <h3><Translate value="404.header" /></h3>
                            <p>
                                <Translate value="404.text" />
                            </p>
                            <Link
                                to="/"
                                className="return-button azi-button azi-button-primary"
                            >
                                <Translate value="404.buttonText" />
                            </Link>
                        </div>
                    </div>
                    <Footer />
                </HelmetProvider>
            </>
        );
    }
}
