import { translate } from "react-i18nify";
import AziContactForm from "../../components/azi-offer-page-sections/azi-contact-form/azi-contact-form";
import AziPackages from "../../components/azi-offer-page-sections/azi-packages/azi-packages";
import AziPriceList from "../../components/azi-offer-page-sections/azi-price-list/azi-price-list";
import Footer from "../../components/footer/footer";
import HeaderSubPage from "../../components/header-subpage/header-subpage";
import Nav from "../../components/nav/nav";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getUrlPath } from "../../translations/translation";

function Offer() {
    window.scrollTo(0, 0);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{translate("metaData.offer.title")}</title>
                    <meta
                        property="og:title"
                        content={translate("metaData.offer.fbTitle")}
                    />
                    <meta
                        name="twitter:title"
                        content={translate("metaData.offer.twitterTitle")}
                    />
                    <meta
                        name="description"
                        content={translate("metaData.offer.description")}
                    />
                    <meta
                        property="og:description"
                        content={translate("metaData.offer.fbDescription")}
                    />
                    <meta
                        name="twitter:description"
                        content={translate("metaData.offer.twitterDescription")}
                    />
                    <link
                        rel="canonical"
                        href={"https://azimuthe.com" + getUrlPath() + "offer"}
                    />
                    <link
                        rel="alternate"
                        hreflang="pl"
                        href="https://azimuthe.com/pl/offer"
                    />
                    <link
                        rel="alternate"
                        hreflang="en"
                        href="https://azimuthe.com/offer"
                    />
                    <link
                        rel="alternate"
                        hreflang="es"
                        href="https://azimuthe.com/es/offer"
                    />
                    <link
                        rel="alternate"
                        hreflang="de"
                        href="https://azimuthe.com/de/offer"
                    />
                    <link
                        rel="alternate"
                        hreflang="uk"
                        href="https://azimuthe.com/ua/offer"
                    />
                </Helmet>
                <Nav />
                <HeaderSubPage text="home.navBar.offer" />
                {/* <div class="sction-prim-white-spacer"></div> */}
                <AziPriceList />
                <div className="section-spacer"></div>
                <AziPackages />
                <div className="section-spacer"></div>
                <AziContactForm />
                <div className="section-spacer"></div>
                <Footer />
            </HelmetProvider>
        </>
    );
}

export default Offer;
