import "./azi-projects.scss?inline";
import React from "react";
import { Translate } from "react-i18nify";

export class AziProjects extends React.PureComponent {
    render() {
        return (
            <>
                <div className="bgc-section">
                    <div className="azi-projects-section section-header-sub-page-spacer">
                        <div className="azi-projects-container">
                            <div data-aos="fade-up" className="project-element">
                                <div className="element-title">
                                    <Translate value="project.project1.title" />
                                </div>

                                <div className="element-description">
                                    <Translate value="project.project1.header" />
                                </div>
                                <div className="element-goal-description">
                                    <Translate value="project.project1.subHeader" />
                                </div>
                                <div className="element-subsidy">
                                    <Translate value="project.project1.financeHeader" />
                                </div>
                                <div className="element-photo">
                                    <img
                                        src="/images/azi-project-page-section/fundusze-europejskie-stopka.svg"
                                        alt="Fundusze europejskie stopka"
                                    />
                                    <img
                                        src="/images/azi-project-page-section/fundusz-badań-i-wdrożeń.svg"
                                        alt="fundusz badań i wdrożeń logo"
                                    />
                                </div>
                                <div className="element-break-line"></div>
                                <div className="element-footer-description">
                                    <Translate value="project.project1.footerDescription" />
                                </div>
                                <div className="element-buttons">
                                    <a
                                         rel="nofollow"
                                        href="https://azimuthe.com/media/projekt_1.pdf"
                                        target="blank"
                                        className="azi-button azi-button-primary"
                                    >
                                        <Translate value="project.offerRequest" />
                                    </a>
                                    <a
                                         rel="nofollow"
                                        href="https://azimuthe.com/media/skan_20210513071413143.pdf"
                                        target="blank"
                                        className="azi-button azi-button-primary"
                                    >
                                        <Translate value="project.supplierSelect" />
                                    </a>
                                </div>
                                <div className="element-footer-photo">
                                    <img
                                        src="/images/azi-project-page-section/kujawsko-pomorska-agencja-innowacji.png"
                                        alt="kujawsko-pomorska agencja innowacji logo"
                                    />
                                    <img
                                        src="/images/azi-project-page-section/pracodawcy-pomorza-i-kujaw.png"
                                        alt="pracodawcy pomorza i kujaw logo"
                                    />
                                    <img
                                        src="/images/azi-project-page-section/izba-przemysłowo-handlowa-toruń.png"
                                        alt="izba toruń przemysłowo-handlowa logo"
                                    />
                                    <img
                                        src="/images/azi-project-page-section/polskie-towarzystwo-ekonomiczne-bydgoszcz.png"
                                        alt="polskie towarzystwo ekonomiczne bydgoszcz logo"
                                    />
                                </div>
                                <div className="element-footer-photo-mobile">
                                    <div className="row-1">
                                        <img
                                            src="/images/azi-project-page-section/kujawsko-pomorska-agencja-innowacji.png"
                                            alt="kujawsko pomorska agencja innowacji logo"
                                        />
                                        <img
                                            src="/images/azi-project-page-section/pracodawcy-pomorza-i-kujaw.png"
                                            alt="pracodawcy pomorza i kujaw logo"
                                        />
                                    </div>
                                    <div className="row-2">
                                        <img
                                            src="/images/azi-project-page-section/izba-przemysłowo-handlowa-toruń.png"
                                            alt="izba przemysłowo handlowa toruń logo"
                                        />
                                        <img
                                            src="/images/azi-project-page-section/polskie-towarzystwo-ekonomiczne-bydgoszcz.png"
                                            alt="polskie towarzystwo ekonomiczne bydgoszcz logo"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div data-aos="fade-up" className="project-element">
                                <div className="spacer-50px"></div>
                                <div className="element-title">
                                    <Translate value="project.project2.title" />
                                </div>

                                <div className="element-description">
                                    <Translate value="project.project2.header" />
                                </div>
                                <div className="element-goal-description">
                                    <Translate value="project.project2.subHeader" />
                                </div>
                                <div className="element-subsidy">
                                    <Translate value="project.project2.financeHeader" />
                                </div>
                                <div className="element-photo">
                                    <img
                                        src="/images/azi-project-page-section/fundusze-europejskie-stopka.svg"
                                        alt="Fundusze europejskie stopka"
                                    />
                                    <img
                                        src="/images/azi-project-page-section/fundusz-badań-i-wdrożeń.svg"
                                        alt="fundusz badań logo"
                                    />
                                </div>
                                <div className="element-break-line"></div>
                                <div className="element-footer-description">
                                    <Translate value="project.project2.footerDescription" />
                                </div>
                                <div className="element-buttons">
                                    <a
                                         rel="nofollow"
                                        href="https://azimuthe.com/media/projekt_2.pdf"
                                        target="blank"
                                        className="azi-button azi-button-primary"
                                    >
                                        <Translate value="project.offerRequest" />
                                    </a>
                                    <a
                                        rel="nofollow"
                                        href="https://azimuthe.com/media/skan_20210513071419958.pdf"
                                        target="blank"
                                        className="azi-button azi-button-primary"
                                    >
                                        <Translate value="project.supplierSelect" />
                                    </a>
                                </div>
                                <div className="element-footer-photo">
                                    <img
                                        src="/images/azi-project-page-section/kujawsko-pomorska-agencja-innowacji.png"
                                        alt="kujawsko pomorska agencja innowacji logo"
                                    />
                                    <img
                                        src="/images/azi-project-page-section/pracodawcy-pomorza-i-kujaw.png"
                                        alt="pracodawcy pomorza i kujaw logo"
                                    />
                                    <img
                                        src="/images/azi-project-page-section/izba-przemysłowo-handlowa-toruń.png"
                                        alt="izba przemysłowo handlowa toruń logo"
                                    />
                                    <img
                                        src="/images/azi-project-page-section/polskie-towarzystwo-ekonomiczne-bydgoszcz.png"
                                        alt="polskie towarzystwo ekonomiczne bydgoszcz logo"
                                    />
                                </div>
                                <div className="element-footer-photo-mobile">
                                    <div className="row-1">
                                        <img
                                            src="/images/azi-project-page-section/kujawsko-pomorska-agencja-innowacji.png"
                                            alt="kujawsko pomorska agencja innowacji logo"
                                        />
                                        <img
                                            src="/images/azi-project-page-section/pracodawcy-pomorza-i-kujaw.png"
                                            alt="pracodawcy pomorza i kujwa logo"
                                        />
                                    </div>
                                    <div className="row-2">
                                        <img
                                            src="/images/azi-project-page-section/izba-przemysłowo-handlowa-toruń.png"
                                            alt="izba przemysłowo handlowa toruń logo"
                                        />
                                        <img
                                            src="/images/azi-project-page-section/polskie-towarzystwo-ekonomiczne-bydgoszcz.png"
                                            alt="polskie towarzystwo ekonomiczne bydgoszcz logo"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AziProjects;
